@use '../../styles/constants.scss' as *;

.information-panel {
  position: relative;
  max-height: 6.5rem;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  box-sizing: border-box;
  border-radius: $border-radius;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 2px 2px 8px 2px rgba(25, 25, 25, 0.3);
  z-index: 3;
  backdrop-filter: blur(4px);
  transition: opacity 0.1s ease-out, max-height 0.4s ease-out;
  opacity: 0;
  pointer-events: none;

  @include media($break-sm) {
    width: 100%;
  }

  &.open {
    max-height: calc(75vh - 45px);
  }

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  .info-hover {
    color: $color-background;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: default;
    opacity: 0;
    transition: all 0.1s ease-out;
    pointer-events: none;
    z-index: 3;
    
    &.show {
      opacity: 1;
      pointer-events: all;

      &:hover {
        transform: scale(1.1);
      }
    }

    span {
      vertical-align: bottom;
      font-size: 1.25rem;
    }
  }

  .info-content {
    transition: all 0.1s ease-out;
    padding: 1rem;
    box-sizing: border-box;
    
    &.absolute {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      z-index: 2;

      &.open {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .contents {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: opacity 0.2s ease-out;
    z-index: 2;
    height: 100%;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    img {
      width: 65%;
      filter: saturate(0.3) brightness(0.65);
    }
  }

  .definitions-container {
    height: 100%;
    transition: all 0.1s ease-out;

    &.hidden {
      opacity: 0;
    }
  }

  .mobile-arrow {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.5rem;
    z-index: 3;

    span {
      transform: rotate(0);
      transition: all 0.2s ease-out;
    }

    &.open {
      span {
        transform: rotate(180deg);
      }
    }
  }
}
