@use '../../styles/constants.scss' as *;

.status {
  position: absolute;
  left: 1px;
  top: 0;
  transform: translateY(calc(-100% - 0.25rem));
  color: $color-background;

  @include media($break-sm) {
    left: 0.5rem;
  }

  &.error {
    color: $color-error;
  }

  .loader {
    display: inline-block;
    width: 4rem;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    img {
      width: 100%;
      filter: saturate(0.3) brightness(0.65);
      margin-bottom: -1.5rem;
    }
  }
}
