@use '../../styles/constants.scss' as *;

.input {
  position: relative;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: $box-shadow;
  backdrop-filter: blur(4px);
  border-radius: $border-radius;

  @include media($break-sm) {
    border-radius: 0;
  }

  .input-form {
    width: 100%;
    background-color: transparent;
    overflow: visible;
    position: relative;
    z-index: 1;
    border-left: none;
    border-bottom: none;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    @include media($break-sm) {
      flex-wrap: wrap;
    }

    .input-box-container {
      flex: 2;
      position: relative;
      display: flex;
      background-color: rgba(255, 255, 255, 0.5);
      border-left: 1px solid rgba(0, 0, 0, 0.4);
      border-right: 1px solid rgba(0, 0, 0, 0.4);

      @include media($break-sm) {
        width: 100%;
        flex: auto;
        order: 2;
        border-left: none;
        border-right: none;
        border-top: 1px solid rgba(42, 71, 46, 0.4);
      }
    }
    
    .input-box {
      border: none;
      box-sizing: border-box;
      padding: 0.5rem 1rem;
      font-family: 'EB Garamond', serif;
      font-size: 1.5rem;
      text-align: center;
      outline: none;
      color: $color-background;
      transition: all 200ms ease-out;
      background-color: transparent;
      width: 100%;
  
      &::placeholder {
        color: $color-background;
      }

      &:focus {
        &::placeholder {
          color: rgba(42, 71, 46, 0.5)
        }
      }
  
      &:disabled {
        color: rgba(42, 71, 46, 0.5);
      }
    }
  }

  .search-bar-button-container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 3rem;

    @include media($break-sm) {
      width: auto;
    }

    &::before {
      content: '';
      position: absolute;
      border-left: 1px solid rgba(42, 71, 46, 0.4);
      left: 0;
      top: 50%;
      height: 60%;
      transform: translateY(-50%);
    }

    span {
      color: $color-background;
      transition: color 0.2s ease-out;

      &:hover {
        font-size: 1.7rem;
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;

      span {
        color: rgba(0, 0, 0, 0.4);

        &:hover {
          font-size: 1.5rem;
        }
      }
    }
  }

  .lang-select-container {
    flex: 1;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-out;

    @include media($break-sm) {
      flex: auto;
      width: 100%;
      padding: 0.25rem 0;
      order: 1;
      box-sizing: border-box;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.disabled {
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
    
    .lang-select {
      background-color: transparent;
      border: none;
      border-radius: 5px;
      font-family: $typeface-primary;
      font-size: 1.1rem;
      width: calc(100% - 2rem);
      height: 100%;
      color: $color-background;
      display: flex;
      align-items: center;

      @include media($break-sm) {
        width: calc(100% - 0.5rem);
      }

      .lang-option {
        background-color: transparent;
        text-align: auto;
      }
    }
  }

  .random-button-container {
    flex: 1;
    display: flex;
    justify-content: center;

    @include media($break-sm) {
      flex: initial;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.disabled {
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .random-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border: none;
      font-family: $typeface-primary;
      color: $color-background;
      background-color: transparent;
      font-size: 1.1rem;
      padding: 0 1rem;
      width: 100%;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s ease-out;

      span {
        margin-right: 0.5rem;
      }

      @include media($break-sm) {
        font-size: 0;
        padding: 0;
        width: 24px;

        span {
          font-size: 1.2rem;
          margin-right: 0;
        }
      }
    }
  }

  .search-bar-button-container, .random-button-container {
    @include media($break-sm) {
      position: relative;
      width: 3rem;

      &::before {
        content: '';
        position: absolute;
        border-left: 1px solid rgba(42, 71, 46, 0.4);
        left: 0;
        top: 50%;
        height: 60%;
        transform: translateY(-50%);
      }
    }
  }

  .initial-message-hover {
    color: $color-background;
    position: absolute;
    top: -0.5rem;
    right: 0;
    cursor: default;
    transition: all 0.1s ease-out;
    z-index: 3;
    transform: translateY(-100%);

    &:hover {
      transform: translateY(-100%) scale(1.1);
    }

    span {
      vertical-align: bottom;
      font-size: 1.25rem;
    }

    @include media($break-sm) {
      right: 0;
      top: 0;

      span {
        padding: 0.3rem;
      }
    }
  }
}

