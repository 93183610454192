@use '../../styles/constants.scss' as *;

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background:
    radial-gradient(
      circle at 100% 0,
      rgba(255, 255, 255, 1),
      rgba(49, 49, 49, 0.25) 90.71%
    ),
    radial-gradient(
      circle at 100% 100%,
      rgba(0, 136, 0, 0.15),
      rgba(0, 255, 0, 0) 80.71%
    ),
    radial-gradient(
      circle at 15% 65%,
      rgba(88, 56, 30, 0.25),
      rgba(0, 0, 0, 0) 80.71%
    );

  .top {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    width: calc(100% - (2 * 45px));
    padding: 45px 45px 0;

    @include media($break-sm) {
      padding: 0;
      width: 100%;
    }
  }
  
  .left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 250px;
    margin: 0 45px;

    @include media($break-sm) {
      width: 100%;
      margin: 0 15px;
    }
  }
  
  .chart-wrapper {
    position: relative;
    flex: 1;
  }
}

