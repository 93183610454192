@use './constants.scss' as *;

html, body {
  font-family: $typeface-primary;
  background-color: $color-white;
  height: 100%;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
