@use '../../styles/constants.scss' as *;

.title {
  color: $color-background;
  font-size: 2.5rem;
  text-shadow: 2px 2px 2px rgba(25, 25, 25, 0.2);
  z-index: 3;
  margin-top: 45px;
  
  @include media($break-sm) {
    margin: 15px 0;
  }
}
